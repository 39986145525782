;(function($) {
	$(function() {
		var $document = $( document );
		var $offer_exit = $( '.offer_exit' );
		var $offer_exit_form = $( '.offer_exit_form' );
		var $email_field = $( '.offer_exit_form_email_field' );
		var $submit_button = $( '.offer_exit_form_button' );
		var $close_button = $( '.offer_exit_close_button' );
		var $offer_exit_confirmation = $( '.offer_exit_confirmation' );
		var $offer_exit_error = $( '.offer_exit_error_text' );

		$email_field.keyup( function( event ) {
			if ( event.keyCode === 13 ) {
				event.preventDefault();
				$submit_button.click();
			}
		});

		$submit_button.on( 'click', function() {
			var email = $email_field.val();
			$offer_exit_error.hide();
			$.ajax( {
				url: '/api/offers',
				method: 'POST',
				data: {
					email: email
				}
			} ).success( function( data, textStatus, jqXHR ) {
				console.log( data, textStatus, jqXHR );
				if ( data && data.message ) {
					$offer_exit_form.hide();
					$offer_exit_confirmation.show();
					Cookies.set( 'offer_exit_dismissed', 'true' );
				}
			} ).fail( function( jqXHR, textStatus, errorThrown ) {
				console.log( jqXHR, textStatus, errorThrown );
				var response = jqXHR.responseJSON;
				if ( response && response.errors ) {
					$offer_exit_error.html( response.errors );
					$offer_exit_error.show();
				}
			} );
		} );

		$close_button.on( 'click', function() {
			$offer_exit.hide();
			Cookies.set( 'offer_exit_dismissed', 'true' );
		} );

		$document.on( 'ecommerce.cart.quantitychange', function() {
			$document.off( 'mouseleave.offer.exit' );
			var quantity = ecommerce.cart.getQuantity();
			console.log( 'ecommerce.cart.quantitychange', quantity );
			if (quantity > 0) {
				$document.on( 'mouseleave.offer.exit', function( event ) {
					if ( event.toElement !== null && event.toElement !== undefined ) {
						return;
					}
					if ( event.relatedTarget !== null && event.relatedTarget !== undefined ) {
						return;
					}
					var shown = Cookies.get( 'offer_exit_dismissed' );
					console.log( 'offer_exit_dismissed', 'shown', shown );
					if ( shown ) {
						return;
					}
					$offer_exit.show();
				} );
			}
		} );
	});
})(jQuery);
